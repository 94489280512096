<style lang="less" scoped>
	.content{
		background-color: #ffffff;
	}
	.seach {
		background: url(../../assets/seachbck.png) no-repeat;
		background-size: cover;
		transition: all 0.5s linear 0s;
		display: flex;
		align-items: center;
	}

	.seachResut {
		background: url(../../assets/seachbck.png) no-repeat;
		background-size: cover;
		transition: all 0.5s linear 0s;
		display: flex;
		align-items: center; 
	}

	.input {
		margin: 0 auto;
		width: 1105px;
		/deep/.el-input{
			display: flex;
			align-items: center;
			
		}
		/deep/.el-input__prefix{
			display: flex;
			align-items: center;
			padding-top: 40px;
		}
		/deep/.el-input__inner{
					background-color: rgba(0,0,0,0);
					border: 0;
					font-size: 38px;
					text-indent: 50px;
					height: 120px;
					border-bottom: 2px solid #FFFFFF;
					color: #FFFFFF;
				}
				
		/deep/.el-input__icon{
			font-size: 56px;
		}
	}

	.award_box {
		display: flex;
		margin-top: 59px;

		div {
			height: 58px;
			border: 1px solid #FFFFFF;
			font-size: 22px;
			color: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 18px;
			margin-right: 24px;
		}
	}

	.award {
		width: 1360px;
		margin: 0 auto;
		text-align: left;
		transition: all 0.5s linear 0s;

		.title {
			font-size: 38px;
			padding: 40px 0;
			span {
				color: #932224;
			}
		}
	}

	.text {
		margin: 56px 0 30px;
		font-size: 32px;
	}

	.cont:hover {
		background: #f5f5f5;
		transition: all 1s linear 0s;
	}

	@keyframes color-change-4x {
		0% {
			background: #ffffff;
		}

		100% {}
	}

	.cont {
		padding: 52px 0;
		display: flex;
		border-bottom: 1px solid #e4e4e4;
		text-align: left;

		.time {
			font-size: 22px;
			color: #666666;
			margin-left: 40px;
			display: flex;
			line-height: 25px;

			.source {
				overflow: hidden;
				-webkit-line-clamp: 1;
				text-overflow: ellipsis;
				display: -webkit-box;
				padding-left: 40px;
				-webkit-box-orient: vertical;
			}
		}

		.text {
			font-size: 28px;
			color: #666666;
			overflow: hidden;
			-webkit-line-clamp: 1;
			text-overflow: ellipsis;
			display: -webkit-box;
			margin: 50px 0 100px 40px;
			-webkit-box-orient: vertical;
		}

		.tittle {
			font-size: 42px;
			font-weight: 500;
			height: 120px;
			color: #000000;
			margin-left: 40px;
			width: 677px;
		}

		img {
			width: 628px;
			height: 346px;
		}
	}

	.content {
		background: #ffffff;
	}

	.content_big_box {
		padding: 58px 0;
		cursor: pointer;
	}

	.content_box {
		width: 1360px;
		height: 670px;
		box-shadow: 6px 4px 9px 0px rgba(186, 186, 186, 0.5);

		.img {
			height: 560px;
			width: 100%;
		}
	}

	/deep/ .el-carousel__container {
		height: 560px;
	}

	.box {
		width: 1362px;
		margin: 0 auto;
	}

	.productName {
		display: flex;
		line-height: 110px;
		padding: 0 33px;

		.left {
			font-size: 38px;
			width: 50%;
			text-align: left;
		}

		.right {
			font-size: 24px;
			color: #999999;
			width: 50%;
			text-align: right;
		}
	}

	.expertSthink_middle {
		padding-bottom: 67px;
		width: 1360px;
		margin: 0 auto;
		flex-wrap: wrap;
		justify-content: flex-start;

		.expertSthink_box {
			width: 25%;
			padding-top: 50px;

			.flex_box {
				// height: 528px;

				.box_img {
					width: 320px;
					height: 390px;
				}
			}

			.box_characters {
				text-align: left;

				.box_name {
					font-size: 22px;
					padding: 16px 0;
				}

				.box_position {
					font-size: 16px;
					padding-bottom: 10px;
				}
			}
		}
	}

	.dialog {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
		z-index: 10000;
		display: flex;
		justify-content: center;
		align-items: center;

		.box {
			width: 804px;
			background-color: white;
			text-align: left;

			.head {
				background: url(../../assets/expertSthinkbg.png);
				background-size: cover;
				font-size: 52px;
				height: 262px;
				padding: 32px 0 0 62px;
				position: relative;

				.time {}

				.name {}

				.position {
					font-size: 18px;
					padding-top: 31px;
				}

				.img {
					position: absolute;
					right: 0;
					top: 0;
					width: 215px;
					height: 262px;
				}

			}

			.foot {
				padding: 56px 0 52px 62px;
				font-size: 22px;

				div {
					padding-bottom: 15px;
				}
			}
		}
	}

	.onlineDisplay_box {
		flex-wrap: wrap;
		justify-content: flex-start;

		.onlineDisplay_image {
			width: 33.33%;
			max-width: 33.33%;
			min-width: 33.33%;
			padding-bottom: 20px;

			.image {
				width: 440px;
				height: 312px;
				color: #FFFFFF;

				.type {
					font-size: 32px;
					margin-top: 58px;
				}

				.time {
					font-size: 32px;
				}

				.track {
					font-size: 16px;
					padding-bottom: 30px;
					border-bottom: 3px solid #FFFFFF;
					width: 50%;
					margin: 0 auto;
				}

				.name {
					padding-top: 30px;
					font-size: 18px;
				}

				.author {
					font-size: 18px;
				}
			}
		}
	}
</style>
<template>
	<div class="content">
		<div :class="show?'seach':'seachResut'" :style="{height:clientheight+'px'}">
			<div class="input">
				<el-input v-model="input" :placeholder="$t('newText.msg94')" prefix-icon='el-icon-search'
					@input="seachChange">
				</el-input>
				<div class="award_box">
					<div @click="input=$t('newText.msg95');seachChange()">{{$t('newText.msg95')}}</div>
					<div @click="input=$t('newText.msg96');seachChange()">{{$t('newText.msg96')}}</div>
					<div @click="input=$t('newText.msg97');seachChange()">{{$t('newText.msg97')}}</div>
				</div>
			</div>
		</div>
		<div class="award" v-if="!show">
			<div class="title">
				{{$t('newText.msg98')}}“{{input}}”{{$t('newText.msg99')}}：<span>{{num}}</span>{{$t('newText.msg100')}}
			</div>
			<div v-show="informationList.length!=0">
				<div class="text">{{$t('home.information')}}</div>
			</div>
			<div v-show="informationList.length!=0">
				<div class="cont" v-for="(item,index) in informationList" :key="index" @click.stop="toUrl(item.id)">
					<div>
						<img :src="item.ne_img" />
					</div>
					<div>
						<div class="tittle text_2_size">{{item.ne_title}}</div>
						<div class="text">{{item.ne_vice_title}}</div>
						<div class="time">
							<div>{{item.create_time}}</div>
							<div class="source">{{$t('information.source')}}：{{item.ne_source}}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-show="productDisplayList.length!=0">
				<div class="text">{{$t('productDisplay.title')}}</div>
			</div>
			<div class="box" v-show="productDisplayList.length!=0">
				<div class="content_big_box" v-for="(item,index) in productDisplayList" :key="index"
					@click.stop="$router.push({path:'productDisplayDetails',query:{id:item.id}})">
					<div class="content_box">
						<div>
							<el-carousel>
								<el-carousel-item v-for="(items,i) in item.en_images" :key="i">
									<img :src="items" class="img" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="productName">
							<div class="left">{{item.en_name}}</div>
							<div class="right">{{$t('productDisplay.time')}}：{{item.update_time}}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-show="expertSthinkList.length!=0">
				<div class="text">{{$t('expertSthink.title')}}</div>
			</div>
			<div class="expertSthink_middle flex_just_disp" v-show="expertSthinkList.length!=0">
				<div class="expertSthink_box" v-for="(item,index) in expertSthinkList" :key="index">
					<div class="flex_box" @click="showDialog(item)">
						<div class="icon_image box_img">
							<img :src="item.avatar" />
						</div>
						<div class="box_characters">
							<div class="box_name">{{item.en_name}}</div>
							<div class="box_position">{{item.en_position}}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-show="onlineDisplayList.length!=0">
				<div class="text">{{$t('inRegard.navList')[1]}}</div>
			</div>
			<div class="onlineDisplay_box flex_disp" v-show="onlineDisplayList.length!=0">
				<div class="onlineDisplay_image" v-for="(item,index) in onlineDisplayList" :key="index" @click.stop="$router.push({path:'onlineDisplayDetails',query:{id:item.id}})">
					<div class="image">
						<image-Obscuration :image="item.en_img" :obscuration="false" :titleShow="true" :larger="false">
							<template v-slot:title>
								<div
									style="position: absolute;bottom: 0;right: 0;font-size: 1.43229vw;color: #f9f7f7;padding: 1.30208vw;">
									<span>{{item.en_name}}</span>
								</div>
							</template>
							<!-- <template v-slot:content>
								<div class="type">{{item.awards.en_reward_name}}</div>
								<div class="time">{{item.en_en_year }}</div>
								<div class="track">{{item.en_track}}</div>
								<div class="name">{{item.en_tag}}</div>
								<div class="author">{{item.author}}</div>
							</template> -->
						</image-Obscuration>
					</div>
				</div>
			</div>
			<div class="dialog" @click.stop="dialogTableVisible=false" v-show="dialogTableVisible">
				<div class="box" @click.stop="">
					<div class="head">
						<div class="time">{{obj.year}}</div>
						<div class="name">{{obj.en_name}}</div>
						<div class="position">{{obj.en_position}}</div>
						<div class="img icon_image">
							<img :src="obj.avatar" />
						</div>
					</div>
					<div class="foot">
						<div v-html="obj.en_text"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import imageObscuration from '../../components/imageObscuration'
	export default {
		components: {
			imageObscuration
		},
		data() {
			return {
				show: true,
				input: "",
				num: 0,
				informationList: [],
				productDisplayList: [],
				obj: {},
				dialogTableVisible: false,
				expertSthinkList: [],
				onlineDisplayList: [],
				clientheight:document.documentElement.clientHeight
			}
		},
		mounted() {

		},
		methods: {
			showDialog(val) {
				this.obj = val
				this.dialogTableVisible = true
			},
			toUrl(id) {
				this.$router.push({
					path: 'InFormationDetail',
					query: {
						id: id
					}
				})
			},
			async seachChange() {
				try {
					let _this = this
					let params = {
						content: this.input,
					}
					let res = await this.$axios.get('index/search', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.num = res.data?res.data.number:0
						this.informationList = res.data?res.data.list.news:[]
						this.productDisplayList = res.data?res.data.list.product:[]
						this.expertSthinkList = res.data?res.data.list.expert:[]
						this.onlineDisplayList = res.data?res.data.list.entries:[],
						this.onlineDisplayList.forEach((item,index)=>{
							item.en_img = item.en_img.split(',')[0]
						})
						console.log(this.onlineDisplayList)
						this.productDisplayList.forEach((item,index)=>{
							item.en_images=item.en_images.split(",")
							item.update_time=this.$util.formatTimeTwo(item.update_time, 'Y-M-D')
						})
						if(this.input!=''){
							this.show=false
							this.clientheight=410
						}else{
							this.show=true
							this.clientheight=document.documentElement.clientHeight
							console.log(this.clientheight)
						}
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
		}
	}
</script>
